.App {
    /* background-color: #FAFCFF; */
    font-family: sans-serif;
    height: 100vh;
}

.App, body {
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    /*background-color: #F4F0FC;*/ /* Does this look good? */ /* We would also have to replace #FFFFFF in multiple locations too */
}

.App-header {
    height: 48px;
    display: flex;
    flex-direction: column;
}

.appBody {
    padding: 1.2rem;
    padding-top: 0.2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.react-toast-notifications__container {
    z-index: 1100 !important;
}

footer {
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(204,204,238,0.9));
    position: fixed;
    display: flex;
    justify-content: center;
    vertical-align: bottom;
    font-size: 15px;
    left: 0;
    bottom: 0;
    padding: 4px;
    padding-bottom: 2px;
    padding-top: 14px;
    height: 50px;
    width: 100%;
    overflow: hidden;
}

div::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
}

div::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 2px #ABABAB; */
    /* -webkit-border-radius: 0px; */
    /* border-radius: 0px; */
    background: #C0C7CB4F !important;
}
 
div::-webkit-scrollbar-thumb {
    -webkit-border-radius: 8px !important;
    border-radius: 6px !important;
    background: rgb(160,180,210) !important;
}

div::-webkit-scrollbar-thumb:hover {
    background: rgb(110,130,160) !important;
}

a:hover {
    cursor: pointer;
}

.favoriteStarSelected {
    color: #FFCD48;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.navigationLinksSection {
    padding-top: 8px;
}
.navigationLinksSection a {
    font-size: 20px;
    font-weight: bold;
    color: #2C5A86;
}

.nolinebreak p {
    display: inline;
}

.inlinegrouping {
    display: inline
}

.calendarModal {
    padding: 0px;
    margin: 0px;
}

.calendarModal input {
    display: none;
}

.radarModal:focus {
    outline: none;
}

.jsonVersionComparison {
    width: 100%;
}

.changedJsonDiff, .reorderedJsonDiff, .changedoraddedJsonDiff, .changedoraddedJsonDiffValue, .previousvaluemovedJsonDiff, .previousvaluemovedJsonDiffValue {
    background-color: #d2f2db !important;
}

.addedJsonDiff, .addedJsonDiffValue, .reorderedaddedJsonDiff, .changedJsonDiffValue, .changedJsonDiffValueString {
    background-color: #98e9ac !important;
}

.reorderedJsonDiffValueString {
    background-color: #f9fb76 !important;
}

.addedJsonDiffDeleted, .changedJsonDiffDeleted, .deletedJsonDiffValue, .deletedJsonDiffLabel, .deletedpreviousvaluemovedJsonDiffLabel {
    background-color: #f9a8b1 !important;
}

.deletedJsonDiff, .changedordeletedJsonDiffValue, .deletedpreviousvaluemovedJsonDiffValueDiv {
    background-color: #f3dde0 !important;
}

.previousvaluemovedJsonDiffValue, .deletedpreviousvaluemovedJsonDiffValue, .deletedpreviousvaluemovedJsonDiffValueElement {
    background-color: #f9ac5c !important;
}

.innerchangeJsonDiff, .innerchangeJsonDiffValue, .arraychangeJsonDiffValue, .arrayorderchangeJsonDiffValue, .reorderedJsonDiff, .changedordeletedJsonDiff {
    /* background-color: #f7f8d5; */
    background-color: #fdfdfa;
}

.reorderedJsonDiff {
    background-color: #f2ecac;
}

.containerSegment {
    background-color: #F5FAFD !important;
    border: 0px !important;
    box-shadow: 0px 0px 4px 1px rgba(34,36,38,.14), 0px 2px 10px 2px rgba(34,36,38,.18) !important;
}

.editSegment {
    background-color: #FFFFE6 !important;
}

.historySegment {
    background-color: #F0DDB8 !important;
}

.measuringTheRateSegment, .measuringTheRateNavigationSegment {
    background-color: #F2FDF6 !important;
}

.remainderOfPageSegment {
    overflow: auto;
    display: block;
    height: calc(100vh - 100px);
}

.maxRemainderOfPageSegment {
    overflow: auto;
    display: block;
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
}

.maxNavigationSegment {
    overflow: auto;
    display: block;
    max-height: calc(100vh - 402px);
}

.maxNavigationCodeSystemSegment {
    overflow: auto;
    display: block;
    height: calc(100vh - 322px);
    max-height: calc(100vh - 322px);
}

.maxNavigationCharacteristicSegment {
    overflow: auto;
    display: block;
    height: calc(100vh - 292px);
    max-height: calc(100vh - 292px);

}

.maxNavigationRobatSegment {
    overflow: auto;
    display: block;
    height: calc(100vh - 272px);
    max-height: calc(100vh - 272px);
    background-color: #fbfbe6 !important;
}

.maxPortalNavigationSegment {
    overflow: auto;
    display: block;
    height: calc(100vh - 172px);
    max-height: calc(100vh - 172px);
    /*background-color: #FAFAFA !important;*/
}

.subSegment {
    background-color: #d7e3ec !important;
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.separator {
    border: 0;
    height: 1px;
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)); */
    /*background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .1), rgba(0, 0, 0, 0));*/
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}
.oppositeSeparator {
    border: 0;
    height: 1px;
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)); */
    /*background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .1), rgba(0, 0, 0, 0));*/
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

input[type=number] {
    -moz-appearance: textfield;
}

.inputLine {
    padding-top: 24px;
}

.inputLine, .outputLine {
    display: flex;
    justify-content: center;
}

.totalInputLine {
    justify-content: left;
}

.studyTitleInput {
    width: 455px;
}

.studyIdentifierInput {
    width: 202px;
}

.studyNumberOfParticipants {
    width: 190px;
}

.segmentInputLine, .picoInputLine {
    padding-top: 0px !important;
}

.populationNameInput, .exposureNameInput {
    width: 40% !important;
}

.populationDescriptionInput, .exposureDescriptionInput {
    width: 100% !important;
}

.agesEligibleDescriptionInput {
    width: 280px !important;
}

.agesEligibleInput {
    width: 140px !important;
}

.leftAlign {
    justify-content: left !important;
}
.slashSeparator {
    font-size: 42px;
    font-weight: bold;
    align-self: center;
}

.rangeToLabel {
    font-size: 24px;
    font-weight: normal;
    align-self: center;
}

.rangeLow, .rangeHigh, .iqrLow, .iqrHigh, .ciLow, .ciHigh {
    width: 103px;
}

.projectNameHeaderInput input {
    font-size: 2rem;
    width: 450px;

}

.inputField {
    background-color: #FFFFFF;
}

.inputField label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.visible {
    display: ""
}

.invisible {
    display: none
}

.inputField:valid label {
    /* background-color: #FFFFFF; */
}

.loadingDimmer {
    cursor: wait;
    z-index: 10000 !important;
}

.detailsInput {
    
}

.inputField input {
    font-size: 18px !important;
    padding: 0px;
}

.outcomeDescription input {
    font-size: 12px !important;    
}

/*
.inputField textarea, .outcomeDescription textarea {
}
*/

.checkboxLessPadding {
    padding: 0px 2px 3px 0px !important;
}

.thinSelector .MuiInputBase-input {
    padding: 2px 50px 4px 4px !important;
}

.thinSelector fieldset {
    border: 0px !important;
    border-bottom: 1px dotted !important;
}

.buttonGroupWrap {
    flex-wrap: wrap !important;
}

.genericToggleButtonGroup button {
    font-size: 14px;
    color: rgb(40, 40, 40);
    text-transform: none !important;
    background-color: #FDFDFD !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border: rgb(60, 60, 60) solid 1px !important;
}

.genericToggleButtonGroup button:hover {
    background-color: #E4E4E4 !important;
}

.yesNoRadioCheckBoxSwitch button {
    font-size: 16px;
    color: rgb(80, 80, 80);
    text-transform: none !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border: rgb(110, 110, 110) solid 1px !important;
    background-color: #FAFAFA !important;
}

.yesNoRadioCheckBoxSwitch button:hover {
    background-color: #E4E4E4 !important;
}

.yesNoRadioCheckBoxSwitch .box, .genericToggleButtonGroup .box {
    font-size: 22px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.yesNoRadioCheckBoxSwitch .yesButton {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-right: rgb(110, 110, 110) dashed 1px !important;
}

.yesNoRadioCheckBoxSwitch .noButton {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-left: rgb(110, 110, 110) dashed 1px !important;
}

.yesNoRadioCheckBoxSwitch.medium button, .genericToggleButtonGroup.medium button {
    padding: 2px 8px 2px 8px !important;
}

.yesNoRadioCheckBoxSwitch.thin button, .genericToggleButtonGroup.thin button {
    padding: 0px 5px 0px 5px !important;
}

.yesNoRadioCheckBoxSwitch.extrathin button, .genericToggleButtonGroup.extrathin button {
    padding: 0px 5px 0px 5px !important;
    line-height: 1.3;
}

.yesNoRadioCheckBoxSwitch.extrathin .yesButton {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.yesNoRadioCheckBoxSwitch.extrathin .noButton {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

/*
.yesNoRadioCheckBoxSwitch button {
    line-height: 1;
}
*/

.genericToggleButtonGroup .MuiToggleButton-primary.Mui-selected {
    color: #FFFFFF !important;
    background-color: rgba(46, 90, 155, 0.80) !important;    
}

.genericToggleButtonGroup .MuiToggleButton-primary.Mui-selected:focus {
    /* background-color: rgba(46, 90, 155, 0.96) !important; */
    background-color: rgba(46, 90, 155, 0.80) !important; 
}

.genericToggleButtonGroup .MuiToggleButton-primary.Mui-selected:active {
    background-color: rgba(46, 90, 155, 0.84) !important; 
}

.genericToggleButtonGroup .MuiToggleButton-primary.Mui-selected:hover {
    background-color: rgba(46, 90, 155, 0.90) !important; 
}

.yesNoRadioCheckBoxSwitch .MuiToggleButton-success.Mui-selected {
    color: #FFFFFF !important;
    background-color: rgba(46, 125, 50, 0.80) !important;
}

.yesNoRadioCheckBoxSwitch .MuiToggleButton-success.Mui-selected:focus {
    /* background-color: rgba(46, 125, 50, 0.96) !important; */
    background-color: rgba(46, 125, 50, 0.80) !important;
}

.yesNoRadioCheckBoxSwitch .MuiToggleButton-success.Mui-selected:active {
    background-color: rgba(46, 125, 50, 0.84) !important;
}

.yesNoRadioCheckBoxSwitch .MuiToggleButton-success.Mui-selected:hover {
    background-color: rgba(46, 125, 50, 0.90) !important;
}

.yesNoRadioCheckBoxSwitch .noButton.Mui-selected {
    color: #FFFFFF !important;
    background-color: rgba(211, 47, 47, 0.80) !important;
}

.yesNoRadioCheckBoxSwitch .noButton.Mui-selected:focus {
    /* background-color: rgba(211, 47, 47, 0.96) !important; */
    background-color: rgba(211, 47, 47, 0.80) !important;
}

.yesNoRadioCheckBoxSwitch .noButton.Mui-selected:active {
    background-color: rgba(211, 47, 47, 0.84) !important;
}

.yesNoRadioCheckBoxSwitch .noButton.Mui-selected:hover {
    background-color: rgba(211, 47, 47, 0.90) !important;
}


.MuiInputBase-multiline, .MuiInputBase-root:not(.MuiInputBase-multiline, .Mui-error, .Mui-disabled) input {
    padding: 8px 10px 8px 10px !important;
}

.MuiInputBase-root .Mui-disabled input {
    padding: 16px 10px 8px 10px !important;
}

.textInputSmallerFont:not(.MuiInputBase-multiline, .Mui-error, .Mui-disabled) input {
    font-size: 14px !important;
    /*padding: 8px 10px 14px 4px !important;*/
}

.textSmaller:not(.MuiInputBase-multiline, .Mui-error, .Mui-disabled) input {
    padding: 3px 6px 3px 6px !important;
}

.textSmaller label:not(.MuiInputLabel-shrink) {
    left: -6px;
    top: -6px;
}

.calendarModal {
    text-align: left;
    /*padding-left: 7px;*/
}

.calendarModal .MuiFormHelperText-root {
    margin-top: 0px;
    line-height: 1.1 !important;
}

.calendarButton {
    margin-right: 0px !important;
}

.radioToggleButton {
    line-height: 0.4em !important;
}

.selectedRadioButton {
    background-color: #ABA7E3 !important;
    -webkit-box-shadow: inset 1px 1px 2px 1px #333 !important;
    -moz-box-shadow:    inset 1px 1px 2px 1px #333 !important;
    box-shadow:         inset 1px 1px 2px 1px #333 !important;
}

.continuousFields {
    text-align: right;
}

.dichContRadio, .attributeRadio, .formButton {
    border: 1px rgba(0,0,0,0.3) solid !important;
}

.highlightedBorder {
    border: 2px solid #DAA520 !important;
}

.thinDropdown {
    min-height: 0px !important;
    min-width: 0px !important;
}
.thinDropdown .icon{
    padding: 0.4em !important;
}

.formButton {
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.5), inset 0 -1px 2px rgba(170, 170, 170, 0.8), 0 3px 5px -3px rgba(100, 100, 100, 0.8) !important;
}

[class="ui button formButton"], [class="ui button compact formButton"], [class="ui button formButton highlightedBorder"] {
    background: #E7EBF1 !important;
}

[class="ui button formButton"]:hover, [class="ui button formButton"]:focus,
[class="ui compact button formButton"]:hover, [class="ui compact button formButton"]:focus
[class="ui button formButton highlightedBorder"]:hover, [class="ui button formButton highlightedBorder"]:focus {
    background: #D7DBE1 !important;
}

[class="ui button formButton"]:active, [class="ui compact button formButton"]:active, [class="ui button formButton highlightedBorder"]:active {
    background: #C7CBD1 !important;
}

.logOutLogInButton {
    background: #F1F2F3 !important;
}

.logOutLogInButton:hover, .logOutLogInButton:focus {
    background: #E1E2E3 !important;
}

.logOutLogInButton:active {
    background: #D1D2D3 !important;
}

.builderButton {
    background: #E6EAD7 !important;
}

.builderButton:hover, .builderButton:focus {
    background: #D6DAC7 !important;
}

.builderButton:active {
    background: #C6CAB7 !important;
}

.builderButtonEmphasis {
    background: #D6DAC7 !important;
}

.builderButtonEmphasis:hover, .builderButtonEmphasis:focus {
    background: #C6CAB7 !important;
}

.builderButtonEmphasis:active {
    background: #B6BAA7 !important;
}

.converterButton {
    background: #F6E7D9 !important;
}

.converterButton:hover, .converterButton:focus {
    background: #E6D7C9 !important;
}

.converterButton:active {
    background: #D6C7B9 !important;
}

.demoButton {
    background: #F3D8Df !important;
}

.demoButton:hover, .demoButton:focus {
    background: #E3C8Cf !important;
}

.demoButton:active {
    background: #D3B8Bf !important;
}

.ballotButton {
    background: #e6d3f7 !important;
}

.ballotButton:hover, .ballotButton:focus {
    background: #CFB8E3 !important;
}

.ballotButton:active {
    background: #BFA8D3 !important;
}

.platformButton {
    background: #DAE3EA !important;
}

.platformButton:hover, .platformButton:focus {
    background: #CAD3DA !important;
}

.platformButton:active {
    background: #BAC3CA !important;
}

.whiteButton {
    background: #ffffff !important;
}

.whiteButton:hover, .whiteButton:focus {
    background: #eaeaea !important;
}

.whiteButton:active {
    background: #dadada !important;
}

.goldenButton {
    background: #BB942D !important;
}

.goldenButton:hover, .goldenButton:focus {
    background: #927323 !important;
}

.goldenButton:active {
    background: #7D631E !important;
}

.navigationButton {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: #EAEFF1 !important;
}

.communicateButton {
    border-bottom: 1px rgba(0,0,0,0.3) dashed !important;
    background: #EAEFF1 !important;
}

.subCommunicateButton {
    border: 0px none !important;
    border-left: 1px rgba(0,0,0,0.3) solid !important;
    border-right: 1px rgba(0,0,0,0.3) solid !important;
    width: 25% !important;
    color: #000000;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: #EAEFF1 !important;
    /*
    color: #000000 !important;
    width: 25% !important;
    margin: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    */
}

.subCommunicateButtonWider {
    border: 0px none !important;
    border-left: 1px rgba(0,0,0,0.3) solid !important;
    border-right: 1px rgba(0,0,0,0.3) solid !important;
    width: 34% !important;
    color: #000000;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: #EAEFF1 !important;
    /*
    color: #000000 !important;
    width: 25% !important;
    margin: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    */
}

.navigationButton:hover, .navigationButton:focus,
.communicateButton:hover, .communicateButton:focus,
.subCommunicateButton:hover, .subCommunicateButton:focus,
.subCommunicateButtonWider:hover, .subCommunicateButtonWider:focus {
    background: #D2D8DC !important;
}

.navigationButton:active, .communicateButton:active, .subCommunicateButton:active, .subCommunicateButtonWider:active {
    background: #C2C8CC !important;
}

.hiddenButtonMultiRowText {
    word-break: break-word !important; /*break-all*/
    text-align: left !important;
    padding-left: 48px !important;
    margin: -14px !important;
    /*padding-right: 0px !important;*/
}

.actionButtonLargerFont {
    font-size: 20px !important;
}

hr.dottedLine {
    border-top: 2px dotted rgb(50, 50, 50, .5);
    margin: 22px 12px 22px 12px;
}

.totalInputBox {
    width: 100px;
}

.projectNameInput {
    width: 300px;
}

.timeOfMeasurement {
    width: 87px;
}

.timeOfMeasurement input {
    height: 17px !important;
}

/*
.dropdown.icon {
    padding-top: 0px !important;
    padding-right: 0px !important;
    top: 0.5em !important;
    right: 0.1em !important;
    font-size: 18px !important;
}*/

.ui.search.dropdown {
    min-width: 40px;
}

.unitOfTimeMeasurementsDropdown {
    width: 114px !important;
    min-width: 114px !important;
}

.loadFromServerDropDown {
    width: 450px !important;
}

.studyTypeDropDown {
    width: 250px !important;
}

.loadFromServerDropDown .dropdown.icon, .studyTypeDropDown .dropdown.icon, .populationDetailsDropdown .dropdown.icon, .populationUnitOfTimeMeasurementsDropdown .dropdown.icon {
    padding-top: 0px !important;
    padding-right: 0px !important;
    top: 1.2em !important;
    right: 1.2em !important;
    font-size: 18px !important;
}

.populationDetailsDropdown {
    width: 280px;
}

.middleAlign {
    vertical-align: bottom !important;
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.box .row.header {
    flex: 0 1 auto;
}

.box .row.content {
    flex: 1 1 auto;
}

.box .row.footer {
    flex: 0 1 40px;
}

h1 {
    margin-bottom: 0px;
}

h2 {
    margin-bottom: 0px;
}

h3 {
    margin-bottom: 0px;
}

.computablePublishingTopHeader {
    padding-top: 0px !important;
    padding-left: 2px !important;
    margin-bottom: 10px !important;
    width: 100%;
}

.bottomNavigationBar {
    position: absolute;
    display: flex;
    text-align: right;
    bottom: 38px;
    width: 100%;
}

.resourceListHeader, .resourceListHeaderExpandAll {
    cursor:pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.resourceListHeaderText {
    font-size: 22px;
    /* font-weight: bold; */
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 0px 0px 16px;
}

.resourceListHeaderTextForProject {
    width: 100%;
    border-bottom: 1px solid #00000099;
    line-height: 0.1em;
}

.resourceListHeaderExpand {
    font-size: 22px;
    /* font-weight: bold; */
    text-align: center;
    width: 100%;
    line-height: 0.1em;
    margin: 0px 0px 16px;    
}

.resourceListHeader span {
    background:#F5FAFD;
    padding:0 10px; 
}
.editSegment .resourceListHeader span {
    background:#FFFFE6;
}

.historySegment .resourceListHeader span {
    background:#f0ddb8;
}

/* Only applies this style to 2nd entries and beyond but NOT the 1st */
.resourceListHeader ~ .resourceListHeader {
    padding-top: 30px;
}

.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input {
   width: 120px !important;
}

.ui.dimmer {
    background-color: rgba(0,0,0,0);
}
.ui.modal {
    box-shadow: 4px 6px 6px 0 rgb(0 0 0 / 20%), 4px 7px 16px 6px rgb(0 0 0 / 20%);
}

.viewmywork {
    height: 95%;
    width: 90% !important;
}

.viewmyworktable {
    position: sticky !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.viewmyworktable thead tr:first-child > th, .viewmyworkcompositetable thead tr:first-child > th {
    position: sticky !important;
    top: -6px !important;
    z-index: 2;
    border-top: 2px solid rgba(34,36,38,.3);
    border-bottom: 2px solid rgba(34,36,38,.3);
    box-shadow: 0px 4px 3px -1px rgba(100, 100, 100, 0.3) !important;
}

.tableFixHead {
    /*overflow-y: auto;
    height: 90%;*/
}

.tableFixHead thead th {
    position: sticky;
    top: 0px;
}

.viewMyWorkModalTableSection .tableFixHead thead th {
    position: sticky;
    top: 50px !important;
}

.radarThinDropdown {
    min-height: 0px !important;
    line-height: 0.5em !important;
    padding: 6px !important;
}

.radarThinDropdown input {
    padding: 2px 6px 2px 6px !important;
}

.radarThinDropdown .item {
    padding: 6px 4px !important;
}

.radarThinDropdown .icon {
    top: 1px !important;
    right: 0.3em !important;
}


.userRatingRow td {
    padding: 4px !important;
}

.adjudicationRow td {
    padding: 10px !important;
}

.expandElementButton {
    background-color: #d3eafc !important;
    border: 1px rgba(0,0,0,0.1) solid !important;
    padding: 2px 6px 2px 6px !important;
}

.dropdownWithClearIconUpperRight .clear {
    padding-top: 0px !important;
    padding-right: 0px !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.noMargins {
    margin: 0px !important;
}

.noTopBottomMargins {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

/* Hides the iframe added by the Zotero Connector browser extension, because this iframe adds a display issue to the website. */
iframe[src*="translateSandbox"] {
    display: none;
}

.gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
  }
  
  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
  
  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  
  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }
  
  .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }
  
  .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }
  
  .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
  }
  
  .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  }
  
  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
  }

  .viewTabPane .ui.secondary.pointing.menu {
    margin-top: -12px;
  }