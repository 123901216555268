.headerTable {
    width: 100%;
    z-index: 100;
}

.leftAlignedCell {
    text-align: left !important;
}

.resultsTable {
    /* border: 2px solid rgba(34,36,38,.4) !important; */
    box-shadow: 2px 6px 6px -4px rgba(100, 100, 100, 0.8) !important;
}

.RecommendationsTable td {
    vertical-align: top;
}


.nothingCell {
    border: none !important;
}

td:first-child:not(.inputCell):not(.calculatedCell):not(.displayPageCell):not(.headerTable td):not(.subTableRow td):not(.noStyleTable>tbody>tr>td):not(.noStyleTable>tr>td):not(.enhancedCharacteristicsTable td) {
    border-top: 2px solid rgba(34,36,38,.4) !important;
}

.resultsTableRow > td:first-child {
    border-left: 3px solid rgba(34,36,38,.5) !important;
}

.resultsTable {
    border-bottom: 3px solid rgba(34,36,38,.5) !important;
    border-right: 3px solid rgba(34,36,38,.5) !important;
}

.compositeComparisonRow td {
    background-color: #E2E2E2;
}

.compositeComparisonSameCell {
    background-color: #81f57d !important;
}

.compositeComparisonMismatchCell {
    background-color: #f57f7d !important;
}

.compositeComparisonAdjudicatedCell {
    background-color: #ffd6d5 !important;
}


td:not(:first-child):not(.headerTable td):not(.subTableRow td):not(.noStyleTable>tbody>tr>td):not(.noStyleTable>tr>td):not(.enhancedCharacteristicsTable td) {
    border-left: 2px solid rgba(34,36,38,.4) !important;
    border-top: 2px solid rgba(34,36,38,.4) !important;
}

th:not(.netEffectCell) {
    border-left: 2px solid rgba(34,36,38,.4) !important;
    text-align: center !important;
}

.rowHeader {
    vertical-align: top;
    width: 400px;
}

.compoundCellTable {
    width: 100%;
    border-collapse: collapse;
}

.trialCell {
    padding: 0px !important;
    margin: 0px !important;
}

.inputCell {
    background: #FFFFFF !important;
    border: none !important;
    font-weight: normal !important;
}

.dichotomousTrialCell {
    vertical-align: bottom;
}

.measuresTable .calculatedCell {
    background: #B6EEBD !important;
    border: none !important;
    border-top: 1px solid rgba(34,36,38,.1) !important;
}

.measuresTable .calculatedTotalCell {
    background: #B6EEBD !important;
}

.measuresTable .calculatedCellMissingInput {
    background: #FDE6CC !important;
}

.trialDisplayCalculatedPercentage {
    font-size: 26px !important;
    padding-top: 2px;
    padding-bottom: 2px;
}

.outcomeName, .outcomeDescription {
    width: 100%;
}

.ui.table thead tr:first-child > th {
    /*position: sticky !important;*/
    top: 0;
    z-index: 2;
    padding: 8px;
    /* border-bottom: 5px solid rgba(34,36,38,.3); */
    /* box-shadow: 0px 4px 3px -1px rgba(100, 100, 100, 0.3) !important; */
}

.displayPageMeasureTopRow, .displayPageMeasureTopRow td {
    border-bottom: 0px !important;
    border-top: 0px !important;
}

.displayPageMeasureBottomRow, .displayPageMeasureBottomRow td {
    border-bottom: 3px solid rgba(38,38,38,.8);
}

.HomePageNavigationTable, .HomePageNavigationTable tr, .HomePageNavigationTable td, .ProjectActionsTable, .ProjectActionsTable tr, .ProjectActionsTable td {
    border: hidden !important;
    border-collapse: collapse !important;
}

.HomePageNavigationTable td, .ProjectActionsTable td {
    padding-right: 12px;
    padding-bottom: 14px;
}


.noStyleTable, .noStyleTable>tbody, .noStyleTable>tbody>tr, .noStyleTable>tr, .noStyleTable>tbody>tr>td, .noStyleTable>tr>td {
    padding: 0px;
    margin: 0px;
    border: none !important;
    border-top: none !important;
}

.enhancedCharacteristicsTable .detailRow td {
    border-top: 1px solid #444444 !important;
    border-bottom: 1px solid rgba(34,36,38,.2) !important;    
    border-left: 2px solid rgba(34,36,38,.4) !important;
}

.enhancedCharacteristicsTable .detailRow td:first-child {
    border-left: 2px solid #444444 !important;
}

.enhancedCharacteristicsTable .detailRow td:last-child {
    border-right: 2px solid #444444 !important;
}

.enhancedCharacteristicsTable .descriptionRow td {
    border-bottom: 2px solid #444444 !important;
    border-left: 2px solid #444444 !important;
    border-right: 2px solid #444444 !important;
}

.enhancedCharacteristicsTable .descriptionRowEdit td {
    border-top: 2px solid #444444 !important;
    border-left: 2px solid #444444 !important;
    border-right: 2px solid #444444 !important;
}